import firebase from 'firebase'
var firebaseConfig = {
  apiKey: "AIzaSyAIakfchuhqr1IwRLtJkB7ykusBdWxwspc",
  authDomain: "startupbundle-ce267.firebaseapp.com",
  projectId: "startupbundle-ce267",
  storageBucket: "startupbundle-ce267.appspot.com",
  messagingSenderId: "556355096127",
  appId: "1:556355096127:web:9d08384a7e00bb2bf6f28a",
  appName: "REACT-PWA-PROJECT"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase