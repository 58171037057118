import React from "react";

const Error = () => {
  return (
    <div style={{ marginTop: "30vh", marginRight: "30vw" }}>
      <h1>Its Error Page</h1>
    </div>
  );
};

export default Error;
