export const LOGIN = "/users/login"
export const SIGNUP = "/users/create"
export const CHANGE_PASSWORD = "/users/changePassword"
export const OTP_VERIFY = "/users/otpVerifyAndChangePassword"
export const FORGOT_PASSWORD = "/users/forgotPassword"
export const GET_USERS = "/users/getUsers"
export const GET_USER = "/users/currentUser/"
export const DELETE_USER = "/users/delete/"
export const UPDATE_USER = "/users/update/"
export const GET_OLD_CHAT = "/conversations/getOldChat"
export const UPLOAD_PROFILE = "/images/uploadSingle"
export const UPLOAD_IMAGES = "/images/uploadMultiple"